(function() {
	const system = $('.comentarios__filtro');

	system.each(function() {
		const $this      = $(this)
		const $select    = $this.find('select');
		const $optionsLI = $this.find('.dropdown-menu li');
		const $optionsA  = $this.find('a[data-value]');
		const $options   = $select.find('option');
		const $output 	 = $this.find('.filterBy');

		$optionsA.each(function() {
			var $link   = $(this);
			var $val    = $link.data('value');
			var $parent = $link.parent('li');
			var $text   = $link.text();

			$link.click(function(e) {
				e.preventDefault();

				$optionsLI.removeClass('selected');
				$parent.addClass('selected');
				$select.val($val).trigger('change');
				$output.text($text);
			});
		});
	});
})();
